import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import '../styles/App.css';

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    sex: '',
    language: '',
    email: '',
    password: '',
    character: '', // Add field for character selection
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;

      // Update the display name
      await updateProfile(user, { displayName: formData.name });

      // Send email verification
      await sendEmailVerification(user);
      alert('A verification email has been sent to your email address. Please verify to complete registration.');

      // Determine the profile picture URL based on selected character
      const profilePictureURL =
        formData.character === 'tiger'
          ? 'https://firebasestorage.googleapis.com/v0/b/jako-9102e.firebasestorage.app/o/profile_pics%2Ftigeravatar.png?alt=media&token=2587d196-18d7-4806-9a12-0a272297a246'
          : 'https://firebasestorage.googleapis.com/v0/b/jako-9102e.firebasestorage.app/o/profile_pics%2Fpandaavatar.png?alt=media&token=8b98f545-a039-4920-b477-7fe17f5c5122';

      // Store additional user info in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name: formData.name,
        age: formData.age,
        sex: formData.sex,
        language: formData.language,
        email: formData.email,
        profilePicture: profilePictureURL, // Store profile picture URL
        highScore: 0,
      });

      // Redirect to login
      navigate('/login');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="page-container">
      <div className="form-container signup-form">
        <h2 className="heading">Sign Up</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="age"
            placeholder="Age"
            value={formData.age}
            onChange={handleChange}
            required
          />
          <select
            name="sex"
            value={formData.sex}
            onChange={handleChange}
            required
            className="transparent-select"
          >
            <option value="" disabled>
              Select Sex
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <select
            name="language"
            value={formData.language}
            onChange={handleChange}
            required
            className="transparent-select"
          >
            <option value="" disabled>
              Select Language to Learn
            </option>
            <option value="japanese">Japanese</option>
            <option value="korean">Korean</option>
          </select>

          {/* Character Selection */}
          <div className="character-selection">
  <h3>Select Your Character</h3>
  <div className="avatar-container">
    <label className="avatar-option-container">
      <input
        type="radio"
        name="character"
        value="tiger"
        onChange={handleChange}
        required
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/jako-9102e.firebasestorage.app/o/profile_pics%2Ftigeravatar.png?alt=media&token=2587d196-18d7-4806-9a12-0a272297a246"
        alt="Tiger Avatar"
        className="avatar-option"
      />
      <span className="avatar-label">Shiro </span>
    </label>
    <label className="avatar-option-container">
      <input
        type="radio"
        name="character"
        value="panda"
        onChange={handleChange}
        required
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/jako-9102e.firebasestorage.app/o/profile_pics%2Fpandaavatar.png?alt=media&token=8b98f545-a039-4920-b477-7fe17f5c5122"
        alt="Panda Avatar"
        className="avatar-option"
      />
      <span className="avatar-label">PenPen </span>
    </label>
  </div>
</div>

          <button type="submit" className="button">
            Sign Up
          </button>
        </form>
        <div className="login-redirect">
          <p>Already have an account?</p>
          <button onClick={() => navigate('/login')} className="button">
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
