// Import the Firebase SDK and necessary services
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

// Firebase configuration from Firebase Console
const firebaseConfig = {
    apiKey: "AIzaSyDnL4LF6nh4cPEXFMsUWGJLrKGftKzmpig",
    authDomain: "jako-9102e.firebaseapp.com",
    projectId: "jako-9102e",
    storageBucket: "jako-9102e.firebasestorage.app",
    messagingSenderId: "350685940236",
    appId: "1:350685940236:web:70603e85808a1d190c7c29",
    measurementId: "G-6Z0P6LPWCR"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app); // Use getFirestore for Firestore
const storage = getStorage(app);

export { app, auth, db, storage, ref, getDownloadURL };