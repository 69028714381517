import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';

// Create Context
const SessionContext = createContext();

// Custom Hook to Access Context
export const useSessionContext = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
};

// Provider Component
export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(null);
  const [userId, setUserId] = useState(null);

  // Start a new session when the user logs in
  const startSession = async (uid) => {
    const sessionStartTime = new Date();
    const sessionDocId = `session-${sessionStartTime.toISOString()}`;
    setSessionId(sessionDocId);
    setUserId(uid);

    try {
      const sessionRef = doc(db, `users/${uid}/traversal`, sessionDocId);
      await setDoc(sessionRef, {
        startTime: sessionStartTime.toISOString(),
        endTime: null,
        pathTraversal: ['home ->'], // Initial page
      });
      console.log('Session started:', sessionDocId);
    } catch (error) {
      console.error('Error starting session:', error);
    }
  };

  // Update traversal data as the user navigates
  const updateTraversal = async (pageName) => {
    if (!userId || !sessionId) return;

    try {
      const sessionRef = doc(db, `users/${userId}/traversal`, sessionId);
      await updateDoc(sessionRef, {
        pathTraversal: arrayUnion(pageName), // Add the new page to the array
      });
      console.log(`Page added to traversal: ${pageName}`);
    } catch (error) {
      console.error('Error updating traversal:', error);
    }
  };

  // End the session when the user logs out
  const endSession = async () => {
    if (!userId || !sessionId) return;

    try {
      const sessionRef = doc(db, `users/${userId}/traversal`, sessionId);
      await updateDoc(sessionRef, {
        endTime: new Date().toISOString(),
      });
      console.log('Session ended:', sessionId);
      setSessionId(null); // Clear session ID after ending
      setUserId(null);
    } catch (error) {
      console.error('Error ending session:', error);
    }
  };

  // Track user authentication status
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        startSession(currentUser.uid); // Start session when the user logs in
      } else {
        endSession(); // End session when the user logs out
      }
    });
    return unsubscribe;
  }, []);

  return (
    <SessionContext.Provider value={{ sessionId, updateTraversal }}>
      {children}
    </SessionContext.Provider>
  );
};

export default useSessionContext;
