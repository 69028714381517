import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from './useSessionTracker'; // Import the session tracker hook
import '../styles/App.css'; // Import the standardized CSS file

const LanguageSelection = () => {
  const navigate = useNavigate();
  const { updateTraversal } = useSessionContext(); // Get the updateTraversal function


  const handleLanguageClick = (language) => {
    updateTraversal(`category-selection (${language}) -> `); // Update traversal for language selection
    navigate(`/category/${language}`);
  };

  const goBack = () => {
    updateTraversal('<- home'); // Update traversal when going back
    navigate('/'); // Go back to home page
  };

  return (
    <div className="page-container">
      {/* Arrow button for navigating back */}
      <button className="arrow-button" onClick={goBack}>
        ←
      </button>
      
      <h1 className="heading">Select a Language to Learn</h1>
      
      <div className="button-group">
        <button className="button" onClick={() => handleLanguageClick('japanese')}>
          Japanese
        </button>
        <button className="button" onClick={() => handleLanguageClick('korean')}>
          Korean
        </button>
      </div>
    </div>
  );
};

export default LanguageSelection;
