import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import defaultUserProfileIcon from '../assets/images/user/userprofile.png'; // Default user profile icon
import emailjs from 'emailjs-com';
import '../styles/App.css';

const Home = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(1); // Default rating set to 1
  const [hoverRating, setHoverRating] = useState(0);
  const [loginMessage, setLoginMessage] = useState(false); // State for login message
  const [thankYouMessage, setThankYouMessage] = useState(false); // Thank-you message for feedback
  const [profilePicture, setProfilePicture] = useState(defaultUserProfileIcon); // Set default icon
  const [sessionId, setSessionId] = useState(null);

  // Check for user authentication status
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        await fetchProfilePicture(currentUser.uid); // Fetch profile picture if user is logged in
        startSession(currentUser.uid);
      } else {
        endSession(); // End session when the user logs out
        setProfilePicture(defaultUserProfileIcon); // Reset profile picture to default
      }
    });
    return unsubscribe;
  }, []);

  // Fetch user's profile picture from Firestore
  const fetchProfilePicture = async (uid) => {
    try {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setProfilePicture(userData.profilePicture || defaultUserProfileIcon); // Use user's profile picture or default icon
      } else {
        console.error('User document does not exist.');
      }
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  // Start a new session when the user logs in
  const startSession = async (uid) => {
    const sessionStartTime = new Date();
    const sessionDocId = `session-${sessionStartTime.toISOString()}`;
    setSessionId(sessionDocId);

    try {
      const sessionRef = doc(db, `users/${uid}/traversal`, sessionDocId);
      await setDoc(sessionRef, {
        startTime: sessionStartTime.toISOString(),
        endTime: null,
        pathTraversal: ['home'], // Initial page
      });
      console.log('Session started:', sessionDocId);
    } catch (error) {
      console.error('Error starting session:', error);
    }
  };

  // Update traversal data as the user navigates
  const updateTraversal = async (pageName) => {
    if (!user || !sessionId) return;

    try {
      const sessionRef = doc(db, `users/${user.uid}/traversal`, sessionId);
      await updateDoc(sessionRef, {
        pathTraversal: arrayUnion(pageName), // Add the new page to the array
      });
      console.log(`Page added to traversal: ${pageName}`);
    } catch (error) {
      console.error('Error updating traversal:', error);
    }
  };

  // End the session when the user logs out
  const endSession = async () => {
    if (!user || !sessionId) return;

    try {
      const sessionRef = doc(db, `users/${user.uid}/traversal`, sessionId);
      await updateDoc(sessionRef, {
        endTime: new Date().toISOString(),
      });
      console.log('Session ended:', sessionId);
      setSessionId(null); // Clear session ID after ending
    } catch (error) {
      console.error('Error ending session:', error);
    }
  };

  // Navigation to language selection page
  const handleStartLearning = () => {
    if (!user) {
      setLoginMessage(true); // Show login message
      setTimeout(() => {
        setLoginMessage(false);
        navigate('/login'); // Redirect to login after 3 seconds
      }, 3000); // Hide it after 3 seconds and redirect
      return;
    }
    updateTraversal('language-selection ->');
    navigate('/language-selection');
  };

  // Popup toggle functions
  const handleLogin = () => {
    setIsPopupVisible(false);
    updateTraversal('login');
    navigate('/login');
  };

  const handleSignUp = () => {
    setIsPopupVisible(false);
    updateTraversal('signup');
    navigate('/signup');
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  // Logout function
  const handleLogout = async () => {
    await endSession(); // Ensure session is ended properly
    await signOut(auth);
    setUser(null);
  };

  // Toggle feedback visibility
  const toggleFeedback = () => {
    setIsFeedbackVisible(!isFeedbackVisible);
  };

  // Handle feedback submission
  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      alert('Please provide some feedback.');
      return;
    }

    if (!user) {
      alert('Please log in to submit feedback.');
      return;
    }

    // Close feedback form and reset fields immediately
    setIsFeedbackVisible(false);
    setFeedback('');
    setRating(1);
    setThankYouMessage(true);

    // Start asynchronous background process
    setTimeout(() => setThankYouMessage(false), 1000); // Hide thank you message after 1 second

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        console.error('User not found.');
        return;
      }
      const userDetails = userDoc.data();

      const templateParams = {
        name: userDetails.name,
        age: userDetails.age,
        sex: userDetails.sex,
        email: userDetails.email,
        language: userDetails.language,
        feedback: feedback,
        rating: rating,
      };

      await emailjs.send(
        'service_mcbk048',
        'template_2js7x7k',
        templateParams,
        'kXy47-cf2ELfowStK'
      );
      console.log('Feedback sent successfully!');
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="page-container">
      {/* Header Section with User Icon */}
      <div className="header">
        <h1 className="heading">Welcome to JaKo Game</h1>
        <img
          src={profilePicture} // Dynamically display the profile picture
          alt="User Profile"
          className="user-profile-icon"
          onClick={togglePopup}
        />
      </div>

      {/* Popup for Login, Signup, or Profile */}
      {isPopupVisible && (
        <div className="popup">
          {user ? (
            <>
              <p>Hello, {user.displayName}</p>
              <button className="popup-button" onClick={() => navigate('/profile')}>
                View Profile
              </button>
              <button className="popup-button" onClick={handleLogout}>
                Logout
              </button>
            </>
          ) : (
            <>
              <button className="popup-button" onClick={handleLogin}>
                Login
              </button>
              <button className="popup-button" onClick={handleSignUp}>
                Sign Up
              </button>
            </>
          )}
        </div>
      )}

      {/* Main Navigation Button */}
      <div className="button-group">
        <button className="button" onClick={handleStartLearning}>
          Start Learning
        </button>
      </div>

      {/* Feedback Icon */}
      <div className="feedback-icon" onClick={toggleFeedback}>
        ?
      </div>

      {/* Feedback Form */}
      {isFeedbackVisible && (
        <div className="feedback-box">
          <h3>Feedback</h3>
          <textarea
            placeholder="Let us know your thoughts..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <div className="star-rating">
            {[...Array(5)].map((_, index) => {
              const starRating = index + 1;
              return (
                <span
                  key={index}
                  className={`star ${
                    starRating <= (hoverRating || rating) ? 'filled' : ''
                  }`}
                  onClick={() => setRating(starRating)}
                  onMouseEnter={() => setHoverRating(starRating)}
                  onMouseLeave={() => setHoverRating(0)}
                >
                  ★
                </span>
              );
            })}
          </div>
          <button onClick={handleFeedbackSubmit}>Submit</button>
        </div>
      )}

      {/* Login Message */}
      {loginMessage && (
        <div className="login-message">Please log in to play the game.</div>
      )}

      {/* Thank You Message */}
      {thankYouMessage && (
        <div className="thank-you-message">Thank you for your feedback!</div>
      )}
    </div>
  );
};

export default Home;
