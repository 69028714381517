import React, { useEffect, useRef, useState, useCallback } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, collection, getDoc, setDoc, updateDoc, addDoc } from 'firebase/firestore';
import { storage } from '../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import Phaser from 'phaser';
import axios from 'axios';
import '../styles/GamePage.css';
import { useParams, useNavigate } from 'react-router-dom';

class JaKoScene extends Phaser.Scene {
  constructor(data) {
    super({ key: 'JaKoScene' });
    this.words = data.words || [];
    this.assets = data.assets || {};
    this.fallingWords = [];
    this.missedWords = [];
    this.wordSpeed = 0.5;
    this.wordDropDelay = 1000;
    this.wordsInSet = 5;
    this.correctInSet = 0;
    this.mode = data.mode;
    this.wordsDropped = 0;
    this.language = data.language;
    this.characterSteps = 0;
    this.characterDirection = 1; // 1 for right, -1 for left
    this.character = null; // Initialize as null
    this.object = null; // Initialize as null
  }



  preload() {
    // Preload assets using Firebase storage URLs passed via props
    this.load.image('bamboo', this.assets.bamboo);
    this.load.image('crown', this.assets.crown);
    this.load.image('bambooLost', this.assets.bambooLost);
    this.load.image('crownLost', this.assets.crownLost);
    this.load.image('panda', this.assets.panda);
    this.load.image('tiger', this.assets.tiger);
    this.load.image('pandaMoving', this.assets.pandaMoving);
    this.load.image('tigerMoving', this.assets.tigerMoving);
    this.load.image('levelup', this.assets.levelup);
    this.load.audio('japanese', this.assets.japaneseAudio);
    this.load.audio('korean', this.assets.koreanAudio);
    this.load.audio('wrongans', this.assets.wrongAnsAudio);
  }



  create() {
    this.events = this.game.events;

    const bgAudioKey = this.language === 'japanese' ? 'japanese' : 'korean';
    this.bgMusic = this.sound.add(bgAudioKey, { volume: 0.5, loop: true });
    this.bgMusic.play();

    // Dynamically calculate positions based on input box
    this.updateCharacterAndObjectPositions();

    // Initialize character
    this.characterKey = this.language === 'japanese' ? 'panda' : 'tiger';
    this.characterMovingKey = this.language === 'japanese' ? 'pandaMoving' : 'tigerMoving';
    this.character = this.add.image(50, this.inputBoxY, this.characterKey)
        .setScale(0.125)
        .setOrigin(0.5, 1) // Anchor point to bottom center
        .setFlipX(false); // Start facing right for the first set

    // Initialize object
    this.objectKey = this.language === 'japanese' ? 'bamboo' : 'crown';
    this.object = this.add.image(this.scale.width - 50, this.inputBoxY, this.objectKey)
        .setScale(0.125)
        .setOrigin(0.5, 1); // Anchor point to bottom center

    this.characterDirection = 1; // Ensure it starts moving right

    // Start the first set after initialization
    this.startNewSet(true); // Pass `true` to indicate this is the first set
}

updateCharacterAndObjectPositions() {
    const inputBox = document.querySelector('.input-container');
    if (!inputBox) {
        console.error('Input container not found!');
        return;
    }

    const inputBoxBounds = inputBox.getBoundingClientRect();
    const canvasBounds = this.game.canvas.getBoundingClientRect();

    // Calculate position relative to the canvas
    this.inputBoxY = inputBoxBounds.top - canvasBounds.top - 15; // Add a gap of 15px
}

startNewSet(isFirstSet = false) {
  if (!this.character || !this.object) {
      console.error('Character or object is not initialized.');
      return;
  }

  // Query the input field's position dynamically
  const inputBox = document.querySelector('.input-container');
  const inputBoxBounds = inputBox.getBoundingClientRect();
  const inputBoxY = inputBoxBounds.top - this.game.canvas.offsetTop - 50; // Add a gap above the input box

  this.correctInSet = 0;
  this.wordsDropped = 0;
  this.characterSteps = 0;
  this.fallingWords.forEach(word => word.destroy());
  this.fallingWords = [];

  // Move the object to the opposite side of the screen immediately
  const isCharacterOnLeft = this.character.x < this.scale.width / 2;
  this.object.setPosition(
      isCharacterOnLeft ? this.scale.width - 50 : 50,
      inputBoxY
  );

  // Adjust character direction only for subsequent sets
  if (!isFirstSet) {
      this.characterDirection *= -1; // Reverse direction
      this.character.setFlipX(this.characterDirection === -1);
  } else {
      this.character.setFlipX(false);
  }

  if (!isFirstSet) {
      // Show the level-up image while waiting for the next set
      this.showLevelUpImage(() => {
          // Generate missed words or a new set after the level-up notification
          if (this.missedWords.length > 0) {
              this.reintroduceMissedWords();
          } else {
              this.generateSet();
          }
      });
  } else {
      // For the first set, just generate words directly
      if (this.missedWords.length > 0) {
          this.reintroduceMissedWords();
      } else {
          this.generateSet();
      }
  }
}


update() {
    this.fallingWords.forEach((wordObj) => {
        if (wordObj) {
            wordObj.y += this.wordSpeed;
            if (wordObj.y > this.scale.height) {
                this.missWord(wordObj);
            }
        }
    });
}


  reintroduceMissedWords() {
    const missedWord = this.missedWords.shift();
    this.generateSpecificWord(missedWord);
  }

  generateSet() {
    for (let i = 0; i < this.wordsInSet; i++) {
      this.time.addEvent({ delay: this.wordDropDelay * i, callback: () => this.generateWord(), callbackScope: this });
    }
  }

  generateWord() {
    const randomIndex = Phaser.Math.Between(0, this.words.length - 1);
    const { original, translation } = this.words[randomIndex];
    const wordToShow = this.mode === "english" ? original : translation;
    const wordObj = this.add.text(
      Phaser.Math.Between(50, this.scale.width - 50),
      -50,
      wordToShow,
      { fontSize: '32px', fill: '#ffffff' }
    );
    wordObj.setInteractive();
    wordObj.correctAnswer = this.mode === "english" ? translation.toLowerCase() : original.toLowerCase();
    wordObj.originalText = { original, translation };

    wordObj.on('pointerdown', () => {
      const textToSpeak = this.mode === "english" ? original : translation;
      this.speakWord(textToSpeak);
    });

    this.fallingWords.push(wordObj);
    this.wordsDropped++;
  }

  generateSpecificWord(wordData) {
    const wordToShow = this.mode === "english" ? wordData.original : wordData.translation;
    const wordObj = this.add.text(
      Phaser.Math.Between(50, this.scale.width - 50),
      -50,
      wordToShow,
      { fontSize: '32px', fill: '#ffffff' }
    );
    wordObj.setInteractive();
    wordObj.correctAnswer = this.mode === "english" ? wordData.translation.toLowerCase() : wordData.original.toLowerCase();
    wordObj.originalText = wordData;

    wordObj.on('pointerdown', () => {
      const textToSpeak = this.mode === "english" ? wordData.original : wordData.translation;
      this.speakWord(textToSpeak);
    });

    this.fallingWords.push(wordObj);
  }

  missWord(wordObj) {
    const wordData = wordObj.originalText;
    this.missedWords.push(wordData);
    this.removeWord(wordObj);
    this.reintroduceMissedWords();
    this.events.emit('life-lost');
  }

  playWrongAnswerSound() {
    this.sound.play('wrongans', { volume: 0.2 });
  }

  showLevelUpImage(callback) {
    // Move the object to the new position immediately before showing the level-up image
    const isCharacterOnLeft = this.character.x < this.scale.width / 2;
    this.object.setPosition(
        isCharacterOnLeft ? this.scale.width - 50 : 50,
        this.inputBoxY
    );

    // Create the level-up image
    const levelUpImage = this.add.image(
        this.scale.width / 2,
        this.scale.height / 2,
        'levelup'
    ).setScale(0.5).setOrigin(0.5);

    // Add a fade-in and fade-out effect for the level-up image
    this.tweens.add({
        targets: levelUpImage,
        alpha: { from: 0, to: 1 },
        duration: 500,
        yoyo: true,
        hold: 1000, // Display for 1 second before fading out
        onComplete: () => {
            levelUpImage.destroy(); // Remove the image
            if (callback) callback(); // Start the next set
        },
    });
}
  moveCharacter() {
    const stepSize = (this.scale.width - 100) / this.wordsInSet;
    const maxPosition = this.scale.width - 50;

    this.character.setTexture(this.characterMovingKey);

    this.tweens.add({
      targets: this.character,
      x: Phaser.Math.Clamp(this.character.x + (stepSize * this.characterDirection), 50, maxPosition),
      duration: 500,
      onComplete: () => {
        this.character.setTexture(this.characterKey);
        this.characterSteps++;

        if (this.characterSteps >= this.wordsInSet) {
          this.events.emit('set-complete', true);

          // Move object to the opposite side of the screen
          const isCharacterOnLeft = this.character.x < this.scale.width / 2;
          this.object.setPosition(
            isCharacterOnLeft ? this.scale.width - 50 : 50,
            this.scale.height - 80
          );

          this.characterDirection *= -1; // Reverse direction
          this.character.setFlipX(this.characterDirection === -1); // Update orientation
          this.characterSteps = 0;
        }
      },
    });
  }

  removeWord(wordObj) {
    const index = this.fallingWords.indexOf(wordObj);
    if (index > -1) {
      this.fallingWords.splice(index, 1);
    }
    wordObj.destroy();
  }

  checkAnswer(input) {
    const normalizedInput = input.trim().toLowerCase();
    const matchedWord = this.fallingWords.find(wordObj => wordObj.correctAnswer === normalizedInput);
    if (matchedWord) {
      this.removeWord(matchedWord);
      this.correctInSet++;
      this.moveCharacter();
      this.missedWords = this.missedWords.filter(missed => missed.translation !== matchedWord.originalText.translation && missed.original !== matchedWord.originalText.original);

      if (this.correctInSet === this.wordsInSet && this.missedWords.length === 0) {
        this.events.emit('set-complete', true);
      }
      return true;
    }
    return false;
  }

  speakWord(text) {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = this.language === 'japanese' ? 'ja-JP' : 'ko-KR';
    window.speechSynthesis.speak(utterance);
  }

}

const GamePage = () => {
  const { language, category, mode } = useParams();
  const navigate = useNavigate();
  const gameRef = useRef(null);
  const [wordPairs, setWordPairs] = useState([]);
  const [assets, setAssets] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [lives, setLives] = useState(3);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const sessionStartTime = useRef(Date.now());
  const gameOverFlag = useRef(false);
  const [backgroundImage, setBackgroundImage] = useState(null); 

  useEffect(() => {
    // Fetch words dynamically from the API
    axios
      .get(`https://getallwords-2vscfffn7q-uc.a.run.app?language=${language}&category=${category}`)
      .then((response) => {
        const pairs = Object.entries(response.data).map(([original, translation]) => ({
          original,
          translation,
        }));
        setWordPairs(pairs);
      })
      .catch((error) => {
        console.error('Error fetching word pairs:', error);
      });
  }, [language, category]);

  // Fetch assets from Firebase Storage
  useEffect(() => {
    const loadAssets = async () => {
      try {
        const assetUrls = await Promise.all([
          getDownloadURL(ref(storage, 'assets/images/lives/bamboo.png')),
          getDownloadURL(ref(storage, 'assets/images/lives/crown.png')),
          getDownloadURL(ref(storage, 'assets/images/lives/bamboo_lost.png')),
          getDownloadURL(ref(storage, 'assets/images/lives/crown_lost.png')),
          getDownloadURL(ref(storage, 'assets/images/character/panda.png')),
          getDownloadURL(ref(storage, 'assets/images/character/tiger.png')),
          getDownloadURL(ref(storage, 'assets/images/character/panda_moving.gif')),
          getDownloadURL(ref(storage, 'assets/images/character/tiger_moving.gif')),
          getDownloadURL(ref(storage, 'assets/images/background/levelup.png')),
          getDownloadURL(ref(storage, 'assets/audio/japanese.mp3')),
          getDownloadURL(ref(storage, 'assets/audio/korean.mp3')),
          getDownloadURL(ref(storage, 'assets/audio/wrongans.mp3')),
          getDownloadURL(ref(storage, `assets/images/background/${language}_bg.jpg`)), // Fetch background
        ]);

        setAssets({
          bamboo: assetUrls[0],
          crown: assetUrls[1],
          bambooLost: assetUrls[2],
          crownLost: assetUrls[3],
          panda: assetUrls[4],
          tiger: assetUrls[5],
          pandaMoving: assetUrls[6],
          tigerMoving: assetUrls[7],
          levelup: assetUrls[8],
          japaneseAudio: assetUrls[9],
          koreanAudio: assetUrls[10],
          wrongAnsAudio: assetUrls[11],
        });
        setBackgroundImage(assetUrls[12]); // Set background image
      } catch (error) {
        console.error('Error loading assets from Firebase Storage:', error);
      }
    };

    loadAssets();
  }, [language]);


  const handleSetCompletion = useCallback((success) => {
    if (success) {
      setScore((prevScore) => {
        const newScore = prevScore + 1;
        console.log("Score updated to:", newScore); // Debug log
        return newScore;
      });
      const scene = gameRef.current.scene.getScene("JaKoScene");
      scene.wordsInSet += 2;
      scene.time.addEvent({
        delay: 3000,
        callback: () => {
          scene.startNewSet();
        },
      });
    }
  }, []);


  const updateHighScore = async (finalScore) => {
    if (!auth.currentUser) {
      console.error("No authenticated user found.");
      return;
    }
  
    const userRef = doc(db, "users", auth.currentUser.uid);
  
    try {
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentHighScore = userData.highScore || 0;
  
        console.log("Current Firebase high score:", currentHighScore); // Debug log
        console.log("Final game score:", finalScore); // Debug log
  
        if (finalScore > currentHighScore) {
          console.log("Updating high score to:", finalScore); // Debug log
          await updateDoc(userRef, { highScore: finalScore });
          console.log("High score updated successfully!");
        } else {
          console.log("No update needed. High score remains unchanged.");
        }
      } else {
        console.log("No user data found. Setting initial high score.");
        await setDoc(userRef, { highScore: finalScore }, { merge: true });
      }
    } catch (error) {
      console.error("Error updating high score:", error);
    }
  };

  const handleLifeLost = useCallback(() => {
    setLives(prevLives => {
      if (prevLives - 1 <= 0) {
        handleGameOver(); // Call this instead to update high score when game is over
        return 0;
      }
      const scene = gameRef.current.scene.getScene('JaKoScene');
      scene.playWrongAnswerSound();
      return prevLives - 1;
    });
  }, []);


  const sessionSavedFlag = useRef(false);

  const saveSessionToFirebase = async (duration, finalScore) => {
    if (sessionSavedFlag.current) {
        console.log("Session already saved. Skipping save.");
        return; // Prevent duplicate saves
    }
    sessionSavedFlag.current = true; // Set the flag

    if (!auth.currentUser) {
        console.error("User not authenticated");
        return;
    }

    try {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, "users", userId, "stats", new Date().toISOString());

        console.log("Saving session data:", { duration, finalScore, language, category, mode });

        await setDoc(userRef, {
            language,
            category,
            typingMode: mode,
            score: finalScore,
            timeSpent: duration,
            date: new Date().toISOString(),
        });
        console.log("Session data saved!");
    } catch (error) {
        console.error("Error saving session data to Firebase:", error);
    }
};
  

  const handleGameOver = () => {
    if (gameOverFlag.current) {
        console.log("Game over already handled. Skipping.");
        return; // Prevent duplicate execution
    }
    gameOverFlag.current = true; // Set the flag to true

    setGameOver(true);

    setScore((prevScore) => {
        console.log("Final score to be checked against high score:", prevScore);

        // Update the high score and save session data
        updateHighScore(prevScore);

        const sessionEndTime = Date.now();
        const duration = Math.floor((sessionEndTime - sessionStartTime.current) / 1000);
        saveSessionToFirebase(duration, prevScore);

        return prevScore; // Return the current score
    });
};

  useEffect(() => {
    if (assets && wordPairs.length && !gameRef.current && Object.keys(assets).length > 0) {
      const config = {
        type: Phaser.CANVAS,
        width: window.innerWidth,
        height: window.innerHeight - 200,
        parent: 'game-container',
        scene: new JaKoScene({ words: wordPairs, mode, language,assets}),
        scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
        },
        transparent: true,
      };

      const game = new Phaser.Game(config);
      game.events.on('set-complete', handleSetCompletion);
      game.events.on('life-lost', handleLifeLost);
      gameRef.current = game;

      return () => {
        if (gameRef.current) {
          gameRef.current.destroy(true);
          gameRef.current = null;
        }
      };
    }
  }, [assets, wordPairs, mode, language, handleSetCompletion, handleLifeLost]);
  if (!assets) {
    return <div>Loading...</div>; // Show loading until assets are fetched
  }

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = () => {
    if (!gameOver && gameRef.current) {
      const scene = gameRef.current.scene.getScene('JaKoScene');
      const input = userInput.trim().toLowerCase();
      if (input) {
        const isCorrect = scene.checkAnswer(input);
        if (!isCorrect) {
          handleLifeLost();
        }
      }
      setUserInput('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const resetGame = () => {
    window.location.reload();
  };

  const handlePlayAgain = () => {
    setGameOver(false);
    setLives(3);
    setScore(0);
    if (gameRef.current) {
      const scene = gameRef.current.scene.getScene('JaKoScene');
      scene.startNewSet();
      scene.scene.resume();
    }
  };

  const handleSelectLanguage = () => {
    navigate(`/language-selection`);
  };

  const handleReturnToCategory = () => {
    navigate(`/category/${language}`);
  };

  const handleBack = () => {
    const routePath = `/rules/${language}/${category}/${mode}`;
    navigate(routePath);
  };

  return (
    <div
      className="page-container"
      style={{
        backgroundImage: `url(${backgroundImage})`, // Use the fetched background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="game-header">
      <button onClick={handleBack} className="back-button">←</button>
      <h2>Learn {category} in {language}</h2>
      <div className="score-lives">
        <div>
          {Array.from({ length: 3 }).map((_, index) => (
            <img
              key={index}
              src={
                lives > index
                  ? language === 'japanese'
                    ? assets.bamboo // Correctly use assets object
                    : assets.crown
                  : language === 'japanese'
                  ? assets.bambooLost // Correctly use assets object
                  : assets.crownLost
              }
              alt="Life Indicator"
              className="life-image"
            />
          ))}
        </div>
        <span>Score: {score}</span>
      </div>
    </div>
    <div id="game-container" style={{ width: '100%', height: `${window.innerHeight - 200}px`, backgroundColor: 'transparent' }} />

      {!gameOver && (
        <div className="input-container">
          <input 
            type="text" 
            value={userInput} 
            onChange={handleInputChange} 
            onKeyPress={handleKeyPress} 
            placeholder="Type the word here..." 
          />
          <button 
            onClick={handleSubmit} 
            className="button"
          >
            Submit
          </button>
          <button 
            onClick={resetGame} 
            className="button"
          >
            Reset
          </button>
        </div>
      )}

      {gameOver && (
        <div className="game-over-overlay">
          <h3>Game Over!</h3>
          <p>Your Final Score: {score}</p>
          <button onClick={handlePlayAgain} className="button">Play Again</button>
          <button onClick={handleSelectLanguage} className="button">Select Language</button>
          <button onClick={handleReturnToCategory} className="button">Return to Category</button>
        </div>
      )}
    </div>
  );
};

export default GamePage;

