import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSessionContext } from './useSessionTracker'; // Import the session tracker hook
import '../styles/App.css'; // Import the standardized CSS file

const ModeSelection = () => {
  const { language, category } = useParams(); // Get language and category from URL parameters
  const navigate = useNavigate(); // Navigation hook
  const { updateTraversal } = useSessionContext(); // Access updateTraversal function


  // Handler for when a mode is selected
  const handleModeClick = (mode) => {
    updateTraversal(`rules (${language}-${category}-${mode}) ->`); // Update traversal with selected mode
    navigate(`/rules/${language}/${encodeURIComponent(category)}/${mode}`);
  };

  // Handle back navigation
  const goBack = () => {
    updateTraversal(`<- (${language}) category`); // Update traversal when navigating back to category
    navigate(`/category/${language}`); // Navigate back
  };

  return (
    <div className="page-container">
      {/* Arrow button for navigating back */}
      <button className="arrow-button" onClick={goBack}>
        ←
      </button>

      <h1 className="heading">
        Select a Mode to Learn in{' '}
        {category.charAt(0).toUpperCase() + category.slice(1)}
      </h1>

      <div className="button-group">
        {/* Learn in English button */}
        <button
          className="button"
          onClick={() => handleModeClick('english')}
        >
          Learn in English
        </button>

        {/* Learn in Original Language button */}
        <button
          className="button"
          onClick={() => handleModeClick('original')}
        >
          Learn in {language.charAt(0).toUpperCase() + language.slice(1)}
        </button>
      </div>
    </div>
  );
};

export default ModeSelection;
