import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import '../styles/App.css';

const ProfilePage = () => {
  return (
    <div className="page-container">
      <h2 className="heading">This page isn't available right now.</h2>
      <p className="profmessage">Please come back at a later time.</p>
    </div>
  );
};
export default ProfilePage;
