import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionContext } from './useSessionTracker'; // Import the session tracker hook
import { storage, getDownloadURL, ref } from '../firebaseConfig'; // Firebase storage import
import '../styles/App.css'; // Import the standardized CSS file

const CategorySelection = () => {
  const { language } = useParams(); // Get the selected language from URL params
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate(); // Navigation hook
  const { updateTraversal } = useSessionContext(); // Access updateTraversal function

  useEffect(() => {
    // Fetch categories from Firebase Storage
    const fetchCategories = async () => {
      try {
        const filePath = `assets/vocabularies/${language}.json`;
        const fileRef = ref(storage, filePath);
        const fileURL = await getDownloadURL(fileRef);
    
        const response = await fetch(fileURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setCategories(Object.keys(data));
        updateTraversal(`category-selection (${language})`); // Track the current page
      } catch (error) {
        console.error('Error fetching vocabulary data:', error);
      }
    };
    

    fetchCategories();
  }, [language, updateTraversal]);

  // Handle back navigation
  const goBack = () => {
    updateTraversal('<- language-selection'); // Update traversal to indicate navigation back to language selection
    navigate(`/language-selection`); // Navigate back
  };

  // Handle category selection
  const handleCategoryClick = (category) => {
    updateTraversal(`mode-selection (${language}-${category}) ->`); // Update traversal with category information
    navigate(`/mode/${language}/${encodeURIComponent(category)}`);
  };

  return (
    <div className="page-container">
      {/* Arrow button for navigating back */}
      <button className="arrow-button" onClick={goBack}>
        ←
      </button>

      <h1 className="heading">
        {language.charAt(0).toUpperCase() + language.slice(1)} Categories
      </h1>
      {categories.length > 0 ? (
        <ul className="button-group">
          {categories.map((category, index) => (
            <li
              key={index}
              className="button"
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </li>
          ))}
        </ul>
      ) : (
        <p>Loading categories...</p> // Show loading message if data is not yet available
      )}
    </div>
  );
};

export default CategorySelection;
