import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSessionContext } from './useSessionTracker'; // Import the session tracker hook
import '../styles/App.css'; // Import the standardized CSS file

const RulesPage = () => {
  const { language, category, mode } = useParams(); // Get language, category, and mode from URL params
  const navigate = useNavigate();
  const { updateTraversal } = useSessionContext(); // Access updateTraversal function

  // Function to navigate to the game page
  const startGame = () => {
    updateTraversal(`game (${language}-${category}-${mode}) ->`); // Update traversal for game page
    navigate(`/game/${language}/${encodeURIComponent(category)}/${mode}`);
  };

  // Updated back navigation
  const goBack = () => {
    updateTraversal(`<- mode-selection (${language}-${category})`); // Update traversal when navigating back
    navigate(`/mode/${language}/${encodeURIComponent(category)}`); // Navigate back to mode selection
  };

  return (
    <div className="page-container">
      {/* Arrow button for navigating back */}
      <button className="arrow-button" onClick={goBack}>
        ←
      </button>

      {/* Heading */}
      <h1 className="heading">
        Rules for {category.charAt(0).toUpperCase() + category.slice(1)} ({mode})
      </h1>

      {/* Content for the rules page */}
      <div className="rules-container">
        <h2>Game Rules:</h2>
        <ul>
          <li>Choose the correct translation for each falling word.</li>
          <li>Type your answer into the input box and press Enter.</li>
          <li>You have three lives; each missed word costs one life.</li>
          <li>If you lose all lives, the game is over.</li>
          <li>Level up by answering all words in a set correctly.</li>
          <li>Each level increases the number of falling words and speed.</li>
        </ul>
      </div>

      {/* Play Game button */}
      <div className="button-group">
        <button className="button" onClick={startGame}>
          Play Game
        </button>
      </div>
    </div>
  );
};

export default RulesPage;
